import React from 'react'
import { FaEnvelope, FaGlobe, FaLocationDot, FaPhone } from "react-icons/fa6";
import { Link } from 'react-router-dom'
import { generalInfo, footerMenu } from '../utils/config'

export const Footer = () => {
  return (
    <>
      <footer className="footer-area overlay text-white pt-120 bgs-cover">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-3 col-md-6 col-sm-8">
              <div className="widget widget_about">
                <div className="logo_footer mb-25">
                  <Link to="/">
                    <img src={generalInfo.logo} alt="Logo" />
                  </Link>
                </div>
                <p>The college was set up on August 09th, 2011 under Govt of Mahrashtra ,MNC, INC & MSBNPE and was headed by Secretory, Kazi Education & Social Welfare Society, Parbhani Mr. Md.Kaleemoddin Md. Moinuddin & Principal, Indira Gandhi Nursing School Mr. Mohd. Khizeruddin Mohd. Moinuddin</p>
                <div className="social-style-one pt-20">
                  {
                    generalInfo.social.map((item, index) => (
                      <Link key={index} to={item.url} target='_blank'><i className={`fab fa-${item.icon}`}></i></Link>
                    ))
                  }
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-4 col-6">
              <div className="widget widget_nav_menu">
                <h5 className="widget-title">Get in touch</h5>
                <ul>
                  <li><FaPhone /> {generalInfo.contact}</li>
                  <li><FaEnvelope /> <Link to={`mailto:${generalInfo.email}`}>{generalInfo.email}</Link></li>
                  <li><FaGlobe /> <Link to={generalInfo.website}>{generalInfo.website}</Link></li>
                  <li><FaLocationDot /> {generalInfo.address}</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-4 col-6">
              <div className="widget widget_nav_menu">
                <h5 className="widget-title">Courses</h5>
                <ul>
                  <li>A.N.M</li>
                  <li>G.N.M</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-4 col-6">
              <div className="widget widget_nav_menu">
                <h5 className="widget-title">Menu</h5>
                <ul>
                  {
                    footerMenu.map((item, index) => (
                      <li key={index}><Link to={item.url}>{item.title}</Link></li>
                    ))
                  }
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom mt-50">
          <div className="container">
            <div className="footer-bottom__inner">
              <div className="donate-by">
                <span>Powered By :</span>
                <Link to="https://www.inshatech.com" target='_blank' >Insha Technologies</Link>
              </div>
              <div className="copyright">
                <p>Copyright 2024-{new Date().getFullYear() + 1} {generalInfo.name} All Right Reserved</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}
