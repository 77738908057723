import React from 'react'
import { circle_with_line_green, circle_with_line_red, half_circle_with_dots } from '../utils/Images'
import { courses } from '../utils/config'
import { Link } from 'react-router-dom'

export const CourseHome = () => {
  return (
    <>
      <div className="urgent-cause-area overlay bgs-cover pt-120 pb-90 rel z-1">
        <div className="container container-1370">
          <div className="row gap-40">
            <div className="col-xl-3 col-md-6">
              <div className="urgent-cause-content mb-30 rmb-65">
                <div className="section-title mb-30">
                  <span className="section-title__subtitle mb-30">Courses Offered</span>
                  <h3>Looking for <span>Admissions?</span></h3>
                </div>
                <p>If you aspire to become a paramedical professional, come be a part of our institute. Register now for more details regarding the wide range of courses that we offer.</p>
                <Link className="btn ml-5 mt-35" to="/courses">View All Courses</Link>
              </div>
            </div>
            {
              courses.map((item, index) => (
                <div key={index} className="col-xl-3 col-md-6">
                  <div className="cause-item">
                    <div className="image">
                      <img src={item.shortImage} alt="Course" />
                    </div>
                    <div className="content">
                      <h5>{item.name}</h5>
                      <p>{item.shortDescription}</p>
                      <div className="cause-price">
                        <span>Duration : {item.duration}</span>
                        <span>Criteria : {item.criteria}</span>
                      </div>
                      <div className="cause-btn">
                        <Link className="btn" to="/contact-us">Join Us Now</Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
        <div className="urgent-cause-shapes">
          <img className="one top_image_bounce" src={half_circle_with_dots} alt="Shape" />
          <img className="two left_image_bounce" src={circle_with_line_red} alt="Shape" />
          <img className="three right_image_bounce" src={circle_with_line_green} alt="Shape" />
        </div>
      </div>
    </>
  )
}
