import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { banner } from '../utils/Images'
import { pageStep } from '../utils/pageStep';

export const PageBanner = () => {
  const { pathname } = useLocation();
  const [parentPage, pageTitle] = pageStep(pathname)
  return (
    <>
      <div className="page-banner-area bgs-cover overlay text-white py-165 rpy-125 rmt-65"
        style={{ backgroundImage: `url(${banner})` }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-8">
              <div className="breadcrumb-inner text-center">
                <h2 className="page-title">{pageTitle}</h2>
                <ul className="page-list">
                  <li><Link to="/">Home</Link></li>
                  {
                    parentPage && <li>{parentPage}</li>
                  }                  
                  <li>{pageTitle}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
