import React, { useState, useEffect } from 'react';
import ResponsiveGallery from 'react-responsive-gallery';
import { importAllImages } from '../../utils/importAllImages';

const ImageGallery = () => {

  const [images, setImages] = useState([]);

  useEffect(() => {
    const images = importAllImages(require.context('../../assets/img/gallery', false, /\.(png|jpe?g|svg)$/));
    setImages(images);
  }, []);

  return (
    <>
      <div>
        <ResponsiveGallery useLightBox media={images} />
      </div>
    </>
  )
};

export default ImageGallery
