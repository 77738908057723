import React, { useEffect } from 'react'

export const BackToTop = () => {

  useEffect(() => {
    const skillbars = document.querySelectorAll('.skillbar');
    skillbars.forEach(skillbar => {
      // Implement skillbar animation logic here
    });

    const backToTopButton = document.querySelector('.back-to-top');
    if (backToTopButton) {
      backToTopButton.addEventListener('click', () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      });
    }

    const handleScroll = () => {
      const navbar = document.querySelector('.navbar');
      backToTopButton.style.display = window.scrollY > 1000 ? 'block' : 'none';
      navbar.classList.toggle('sticky-active', window.scrollY >= 445);
    };

    window.addEventListener('scroll', handleScroll);

    window.addEventListener('load', () => {
      const preloader = document.querySelector('#preloader');
      if (preloader) preloader.style.display = 'none';
      if (backToTopButton) backToTopButton.style.display = 'none';
    });

    return () => {
      if (backToTopButton) {
        backToTopButton.removeEventListener('click', () => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        });
      }
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="back-to-top">
      <span className="back-top"><i className="fa fa-angle-up"></i></span>
    </div>
  )
}
