import React from 'react'
import { heroBg } from '../utils/Images'
import { Link } from 'react-router-dom'

export const Hero = () => {
  return (
    <>
      <div className="hero-area bgs-cover overlay pt-155 pb-170" style={{ backgroundImage: `url(${heroBg})` }}>
        <div className="container container-1370">
          <div className="hero-content text-center text-white">
            <h1>Welcome to The
              Indira Gandhi Nursing Institute</h1>
            <p>The college was set up on August 09th, 2011 under Govt of Mahrashtra,
              MNC, INC & MSBNPE and was headed by Secretory, Kazi Education & Social Welfare Society, Parbhani
              Mr. Md.Kaleemoddin Md. Moinuddin & Principal, Indira Gandhi Nursing School, Parbhani
              Mr. Mohd. Khizeruddin Mohd. Moinuddin</p>
            <div className="hero-btns pt-30 rpt-10">
              <Link className="btn" to="/about/about-us">Read More</Link>
              <Link className="btn btn--yellow btn--style-two" to="/contact-us">Contac us</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
