import { Route, Routes } from "react-router-dom";
import { Home } from "./pages/Home";
import { NavbarTop } from "./components/NavbarTop";
import { Navbar } from "./components/Navbar";
import { Footer } from "./components/Footer";
import { BackToTop } from "./components/widget/BackToTop";
import { Contact } from "./pages/Contact";
import Courses from "./pages/Courses";
import Gallery from "./pages/Gallery";
import ScrollToTop from "./components/ScrollToTop";
import CommonPage from "./pages/CommonPage";
import Error from "./pages/Error";

function App() {
  return (
    <>
      <NavbarTop />
      <Navbar />
      <ScrollToTop/>
      <Routes>
        <Route path="/" element={<Home />} />
        
        <Route path="about/about-us" element={<CommonPage />} />
        <Route path="about/our-staff" element={<CommonPage />} />
        <Route path="about/president-desk" element={<CommonPage />} />
        <Route path="about/principal-desk" element={<CommonPage />} />

        <Route path="/infrastructure" element={<CommonPage />} />

        <Route path="/courses" element={<Courses />} />

        <Route path="/gallery/image-gallery" element={<Gallery />} />

        <Route path="/contact-us" element={<Contact />} />

        <Route path="*"  element={<Error/>} />
      </Routes>
      <Footer />
      <BackToTop />
    </>
  );
}

export default App;
