import { useState, useRef } from "react";
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';

const ContactForm = () => {
  const form = useRef();
  const [isProcessing, setIsProcessing] = useState(false);

  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  };

  const validateMobile = (mobile) => {
    const regex = /^[6-9]\d{9}$/;
    return regex.test(mobile);
  };

  const sendEmail = (e) => {
    e.preventDefault();
    setIsProcessing(true);

    const email = form.current.user_email.value;
    const mobile = form.current.user_mobile.value;

    if (!validateEmail(email)) {
      setIsProcessing(false);
      Swal.fire('Invalid Email!', 'Please enter a valid email address.', 'error');
      return;
    }

    if (!validateMobile(mobile)) {
      setIsProcessing(false);
      Swal.fire('Invalid Mobile!', 'Please enter a valid 10-digit Indian mobile number.', 'error');
      return;
    }

    emailjs
      .sendForm('service_jfhbfba', 'template_pqz5h42', form.current, {
        publicKey: 'XUr0U5zkDI1gdngzr',
      })
      .then(
        () => {
          Swal.fire('Success!', 'Your message has been sent successfully.', 'success');
          form.current.reset();
          setIsProcessing(false);
        },
        (error) => {
          Swal.fire('Failed!', 'Something went wrong. Please try again later.', 'error');
          setIsProcessing(false);
        },
      );
  };
  return (
    <>
      <div class="col-lg-6">
        <div class="contact-page-form form-style-two py-110 rpy-85">
          <div class="section-title mb-10">
            <span class="section-title__subtitle mb-10">Need help</span>
            <h3>Get In touch</h3>
          </div>
          <form ref={form} onSubmit={sendEmail}>
            <div class="row">
              <div class="col-xl-9 mb-10">
                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt</p> */}
              </div>
              <div class="col-sm-12">
                <div class="form-group">
                  <label for="user_name">Your Name</label>
                  <input type="text" id="user_name" name="user_name" class="form-control" placeholder="Your Name" required />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="user_email">Your Email</label>
                  <input type="email" id="user_email" name="user_email" class="form-control" placeholder="Email Address" required />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="user_mobile">Mobile Number</label>
                  <input type="text" id="user_mobile" name="user_mobile" class="form-control"
                    placeholder="Mobile Number" required />
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label for="user_message">Message</label>
                  <textarea name="user_message" id="user_message" class="form-control" rows="2"
                    placeholder="Write Your Messages" required></textarea>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group pt-10 mb-0">
                  <button type="submit" class="btn ml-5"disabled={isProcessing}>
                  {isProcessing ? 'Processing...' : 'Send us a message'}</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
};

export default ContactForm
