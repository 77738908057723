import React from 'react'
import { Hero } from '../components/Hero'
import { CourseHome } from '../components/CourseHome'
import { AboutHome } from '../components/AboutHome'

export const Home = () => {
  return (
    <>
      <Hero/>
      <AboutHome/>
      <CourseHome/>
    </>
  )
}
