import { about, anm, anm_b, gnm, gnm_b, infra, logo } from "./Images";

export const imagesGalleryPath = "../../assets/img/gallery";

export const generalInfo = {
  name: "Indira Gandhi Nursing School",
  contact: "9923194784, 9923003895, 9588471729",
  address: "KHADRABAD PLOT, NEAR JINTUR ROAD HIGHWAY, PARBHANI-431401",
  email: "contact@indiragandhinursing.in",
  website: "https://www.indiragandhinursing.in",
  social: [
    {
      platform: "facebook",
      url: "https://www.facebook.com/indiragandhinursing",
      icon: "facebook-f",
    },
  ],
  logo: logo,
};

export const courses = [
  {
    name: "Auxiliary Nursing Midwifery (ANM)",
    shortDescription:
      "The ANM full form is Auxiliary Nursing Midwifery. A paramedical diploma programme called GNM lasts for 2 years.",
    shortImage: anm,
    image: anm_b,
    duration: "2 Years",
    criteria: "12Th Pass",
  },
  {
    name: "General Nursing and Midwifery (GNM)",
    shortDescription:
      "The full form of GNM is General Nursing and Midwifery. A paramedical diploma programme called GNM lasts for three and a half years.",
    shortImage: gnm,
    image: gnm_b,
    duration: "3.5 Years",
    criteria: "12Th Pass",
  },
];

export const mainMenu = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "About",
    url: "#",
    subMenu: true,
    tag: "about-us",
    menu: [
      {
        title: "About Us",
        url: "/about/about-us",
      },
      {
        title: "Our Staff",
        url: "/about/our-staff",
      },
      {
        title: "President Desk",
        url: "/about/president-desk",
      },
      {
        title: "Principal Desk",
        url: "/about/principal-desk",
      },
    ],
  },
  {
    title: "Infrastructure",
    url: "/infrastructure",
  },
  {
    title: "Courses",
    url: "/courses",
  },
  {
    title: "Gallery",
    url: "#",
    subMenu: true,
    tag: "gallery",
    menu: [
      {
        title: "Image Gallery",
        url: "/gallery/image-gallery",
      },
    ],
  },
  {
    title: "Contact Us",
    url: "/contact-us",
  },
];

export const footerMenu = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "About",
    url: "/about/about-us",
  },
  {
    title: "Infrastructure",
    url: "/infrastructure",
  },
  {
    title: "Courses",
    url: "/courses",
  },
  {
    title: "Gallery",
    url: "/gallery/image-gallery",
  },
  {
    title: "Contact Us",
    url: "/contact-us",
  },
];

export const pages = [
  {
    url: "about-us",
    img: about,
    description: `
      <p>The college was set up on August 09th, 2011 under Govt of Mahrashtra ,MNC, INC & MSBNPE and was headed by Secretory, Kazi Education & Social Welfare Society, Parbhani Mr. Md.Kaleemoddin Md. Moinuddin & Principal, Indira Gandhi Nursing School Mr. Mohd. Khizeruddin Mohd. Moinuddin </p>
      <p>There has been an increasing demand for nurses across the world, thereby making nursing a lucrative and viable career option. Indira Gandhi Nursing School is among the first private colleges to provide various academic offerings in Nursing, ranging from ANM & GNM to Degree.</p>
      <p>We're a leading nursing institute, empowering future healthcare professionals since 2011. With a team of dedicated educators and state-of-the-art facilities, we're committed to excellence in nursing education. Our institute is renowned for its quality service, earning accolades from both local and global communities. Join us and become part of a legacy dedicated to shaping the future of healthcare system.</p>
    `,
  },
  {
    url: "our-staff",
    img: infra,
    description: `
    <p>
      At <strong>${generalInfo.name}</strong>, our staff is the cornerstone of the exceptional education and training that we provide to our students. With a strong commitment to nurturing future healthcare professionals, our faculty and administrative teams bring a wealth of experience, knowledge, and dedication to the campus environment.
    </p>

    <h3>1. Experienced Faculty</h3>
    <p>
      Our <strong>ANM (Auxiliary Nurse Midwife)</strong> and <strong>GNM (General Nursing and Midwifery)</strong> programs are led by a team of highly qualified and experienced nursing educators. Each member of our faculty holds advanced degrees in nursing and midwifery and has hands-on experience in the medical field. Their blend of theoretical expertise and practical knowledge ensures that our students receive a comprehensive, up-to-date education.
    </p>
    <ul>
      <li><strong>Expertise in Nursing & Midwifery:</strong> Our faculty has experience across a wide spectrum of healthcare settings, including hospitals, community health centers, and clinical practices.</li>
      <li><strong>Personalized Guidance:</strong> We believe in providing individualized attention to our students, fostering an environment where every learner can thrive.</li>
      <li><strong>Continuous Professional Development:</strong> Our educators are committed to staying at the forefront of advancements in the nursing field, ensuring that students are exposed to the latest in healthcare practices and technology.</li>
    </ul>

    <h3>2. Clinical Instructors</h3>
    <p>
      Our clinical instructors play a crucial role in bridging the gap between classroom learning and real-world application. They supervise students during clinical rotations, offering mentorship and guidance in real-time as students practice essential nursing skills.
    </p>
    <ul>
      <li><strong>Hands-on Supervision:</strong> They ensure students are fully supported as they navigate clinical environments.</li>
      <li><strong>Real-World Insights:</strong> Drawing from their vast clinical experience, they offer practical tips and techniques that can only be learned on the job.</li>
    </ul>

    <h3>3. Administrative Staff</h3>
    <p>
      Our administrative staff is the backbone of our college, ensuring that day-to-day operations run smoothly. From admissions to academic scheduling, their dedication ensures that our students have a seamless experience from the moment they step into the college until they graduate.
    </p>
    <ul>
      <li><strong>Student-Centric Support:</strong> We are always available to address any academic or administrative needs of students.</li>
      <li><strong>Streamlined Processes:</strong> Our team works efficiently to handle everything from admissions and enrollments to scheduling and student services.</li>
    </ul>
    `,
  },
  {
    url: "president-desk",
    img: infra,
    description: `
      <div>
        <p>Dear Students, Faculty, and Visitors,</p>
        <p>
          It is with great pride and immense pleasure that I welcome you to our ANM & GNM College, an institution
          dedicated to nurturing compassionate and skilled healthcare professionals. Nursing is a noble profession, deeply
          rooted in care, compassion, and the desire to serve humanity. As we strive to mold the next generation of nurses,
          our aim is not only to impart academic knowledge but also to instill the values of empathy, dedication, and
          ethical practice in our students.
        </p>
        <p>
          In today's rapidly evolving healthcare environment, the role of nurses has expanded significantly, and the
          demand for highly trained professionals has never been greater. We are committed to providing our students with
          the best possible education and hands-on clinical experience, ensuring they are well-equipped to meet the
          challenges of modern healthcare.
        </p>
        <p>
          Our curriculum is designed to foster both theoretical knowledge and practical skills, supported by a team of
          experienced educators and healthcare professionals. We emphasize a holistic approach to learning, integrating
          advanced medical techniques with personalized patient care, while also encouraging leadership, teamwork, and
          continuous self-improvement.
        </p>
        <p>
          I encourage all students to take full advantage of the resources, facilities, and opportunities provided by the
          college. As you embark on this rewarding journey in the field of nursing, know that your contributions will have a
          profound impact on individuals, families, and communities.
        </p>
        <p>
          On behalf of the faculty and administration, I wish you success in all your academic and professional endeavors.
          Together, let us strive for excellence and make a meaningful difference in the world of healthcare.
        </p>

        <p>Warm regards,</p>
        <p><strong>Mr. Mohd. Khizeruddin Mohd. Moinuddin</strong><br>${generalInfo.name}, Parbhani</p>
      </div>
    `
  },
  {
    url: "principal-desk",
    img: infra,
    description: `
      <p>Welcome to ${generalInfo.name}, where we are dedicated to shaping the future of healthcare through excellence in nursing education. As the Principal, I am honored to lead an institution that stands at the forefront of nursing education, committed to nurturing skilled and compassionate healthcare professionals.</p>
        
      <p>At ${generalInfo.name}, our mission is to provide an enriching learning environment that empowers students with the knowledge, skills, and ethical values essential for delivering quality patient care. We offer comprehensive ANM and GNM programs designed to prepare our students for the challenges and opportunities in the dynamic field of nursing.</p>
      
      <p>Our faculty comprises experienced and passionate educators who are committed to delivering high-quality instruction and mentorship. They bring a wealth of practical experience and academic expertise to the classroom, ensuring that our students receive the best possible education.</p>
      
      <p>We believe in a holistic approach to nursing education that combines rigorous academic coursework with hands-on clinical practice. Our state-of-the-art facilities and partnerships with leading healthcare institutions provide students with invaluable practical experience, preparing them to excel in their careers.</p>
      
      <p>As you explore our website, I encourage you to learn more about our programs, faculty, and the vibrant community we have built here at ${generalInfo.name}. We are proud of our tradition of excellence and look forward to welcoming new students who share our commitment to advancing the field of nursing.</p>
      
      <p>Thank you for considering ${generalInfo.name} as your pathway to a rewarding and impactful career in nursing. Together, we will work towards making a difference in the lives of patients and communities.</p>
      
      <p class="signature">Warm regards,<br>Mr. Md.Kaleemoddin Md. Moinuddin<br>Principal, ${generalInfo.name}</p>
    `,
  },
  {
    url: "infrastructure",
    img: infra,
    description: `
      <p>At ${generalInfo.name}, we are committed to providing our students with a conducive learning environment that combines modern facilities with a supportive educational atmosphere. Our infrastructure is designed to ensure that both ANM and GNM students receive the best possible training and hands-on experience in their respective fields. Here's an overview of our key infrastructure features:</p>

      <div class="facility">
        <h3>Modern Classrooms</h3>
        <p>Our classrooms are equipped with the latest technology to enhance the learning experience. Each room features multimedia projectors, interactive whiteboards, and comfortable seating arrangements to foster effective teaching and learning.</p>
      </div>
      
      <div class="facility">
        <h3>Advanced Laboratories</h3>
        <p>We have well-equipped laboratories for practical training in nursing and midwifery. These include:</p>
        <ul>
          <li><strong>Skills Lab:</strong> Designed for simulation-based training, it includes mannequins, medical equipment, and supplies for hands-on practice.</li>
          <li><strong>Anatomy Lab:</strong> Featuring anatomical models and charts to aid in the study of human anatomy and physiology.</li>
          <li><strong>Pharmacology Lab:</strong> Equipped with the latest tools and resources for studying drug administration and pharmacological processes.</li>
        </ul>
      </div>
      
      <div class="facility">
        <h3>Clinical Training Facilities</h3>
        <p>Our college has partnerships with leading hospitals and healthcare institutions, providing students with opportunities for real-world clinical experience. These facilities include:</p>
        <ul>
          <li><strong>Simulation Center:</strong> A high-fidelity simulation center that mimics real-life medical scenarios for immersive learning.</li>
          <li><strong>Clinical Rotations:</strong> Access to various departments in affiliated hospitals for practical exposure to patient care and medical procedures.</li>
        </ul>
      </div>
      
      <div class="facility">
        <h3>Library and Resource Center</h3>
        <p>The college boasts a well-stocked library with an extensive collection of textbooks, journals, and research papers. Our digital resource center provides access to online databases, e-books, and academic journals to support research and study.</p>
      </div>
      
      <div class="facility">
        <h3>Student Hostels</h3>
        <p>We offer comfortable and secure hostel accommodations for students. Our hostels are equipped with modern amenities, including Wi-Fi, recreational areas, and 24/7 security, ensuring a safe and conducive living environment.</p>
      </div>
      
      <div class="facility">
        <h3>Cafeteria</h3>
        <p>Our cafeteria serves a variety of nutritious and balanced meals, catering to diverse dietary preferences. It provides a comfortable space for students to relax and socialize.</p>
      </div>
      
      <div class="facility">
        <h3>Sports and Recreation</h3>
        <p>We believe in the holistic development of our students. Our campus includes sports facilities such as a gymnasium, sports courts, and open spaces for physical activities and recreational events.</p>
      </div>
      
      <div class="facility">
        <h3>Health and Wellness Center</h3>
        <p>A dedicated health center on campus provides medical services, counseling, and wellness programs to support the overall well-being of our students.</p>
      </div>
      
      <div class="facility">
        <h3>Administrative Support</h3>
        <p>Our administrative offices are equipped to provide efficient support for student services, including admissions, counseling, and career guidance.</p>
      </div>
    `,
  }
];
