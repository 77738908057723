import { useLocation } from "react-router-dom";
import { PageBanner } from "../components/PageBanner";
import { pageStep } from "../utils/pageStep";
import { pages } from "../utils/config";

const CommonPage = () => {
  const { pathname } = useLocation();
  const [,pageTitle, pageUrl] = pageStep(pathname)  
  const pageData = pages.find(page => page.url === pageUrl);
  return (
    <>
      <PageBanner />
      <div className="portfolio-details-area pt-120 pb-105">
        <div className="container">
          <div className="portfolio-details-content">
            <div className="details-image mb-50">
              <img src={pageData?.img} alt={pageTitle} />
            </div>
            <h3 className="title mb-20">{pageTitle}</h3>
            <div dangerouslySetInnerHTML={{ __html: pageData?.description }} />
          </div>
        </div>
      </div>
    </>
  )
};

export default CommonPage
