import ImageGallery from "../components/gallery/ImageGallery";
import { PageBanner } from "../components/PageBanner";

const Gallery = () => {
  return (
    <>
      <PageBanner />
      <div class="our-cause-page py-120 rel z-1">
        <div class="container">
          <div class="row justify-content-center">
            <ImageGallery/>
          </div>
        </div>
      </div>
    </>
  )
};

export default Gallery
