import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { generalInfo, mainMenu } from '../utils/config';

export const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [expandedMenu, setExpandedMenu] = useState(null);

  const handleToggleMenu = () => {
    setToggleMenu(!toggleMenu);
  };

  const handleExpandMenu = (menu) => {
    setExpandedMenu(expandedMenu === menu ? null : menu);
  };

  return (
    <nav className="navbar py-30 navbar--one navbar-area navbar-expand-lg">
      <div className="container nav-container navbar-bg">
        <div className="responsive-mobile-menu">
          <button
            className={`menu toggle-btn d-block d-lg-none ${toggleMenu ? 'open' : ''}`}
            onClick={handleToggleMenu}
            data-target="#Iitechie_main_menu"
            aria-expanded={toggleMenu}
            aria-label="Toggle navigation"
          >
            <span className="icon-left"></span>
            <span className="icon-right"></span>
          </button>
        </div>
        <div className="logo">
          <Link to="/">
            <img src={generalInfo.logo} alt="logo" height="50px" />
          </Link>
        </div>
        <div className="nav-right-part nav-right-part-mobile">
          <Link className="search-bar-btn" to="#">
            <i className="flaticon-magnifying-glass"></i>
          </Link>
        </div>
        <div className={`collapse navbar-collapse ${toggleMenu ? 'sopen' : ''}`} id="Iitechie_main_menu">
          <ul className="navbar-nav menu-open text-lg-end">
            {
              mainMenu.map((item) => (
                <li key={item.tag} className={`menu-item ${item.subMenu ? 'menu-item-has-children' : ''}`}>
                  <Link to={item.url} onClick={item.subMenu ? () => handleExpandMenu(item.tag) : undefined}>
                    {item.title}
                  </Link>
                  {
                    item.subMenu &&
                    (
                      <ul className={`sub-menu ${expandedMenu === item.tag ? 'd-block' : 'd-none'}`}>
                        {
                          item.menu.map((menuItem) => (
                            <li key={menuItem.tag}><Link to={menuItem.url}>{menuItem.title}</Link></li>
                          ))
                        }
                      </ul>
                    )
                  }
                </li>
              ))
            }
          </ul>

        </div>
        <div className="nav-right-part nav-right-part-desktop">
          {/* <Link className="search-bar-btn" to="#">
            <i className="flaticon-magnifying-glass"></i>
          </Link>
          <div className="dropdown">
            <Link className="dropdown-toggle" to="#">
              <i className="flaticon-user-1"></i>
            </Link>
          </div> */}
          <Link className="btn btn--style-two" to="/contact-us">Admission Now</Link>
        </div>
      </div>
    </nav>
  );
};
