import React from 'react'
import { PageBanner } from '../components/PageBanner'
import { Link } from 'react-router-dom'
import { contactImg } from '../utils/Images'
import ContactForm from '../components/ContactForm'

export const Contact = () => {
  return (
    <>
      <PageBanner />
      {/* <!-- Contact page area start --> */}
      <div class="contact-page-area overflow-hidden py-120 rpt-100">
        <div class="container">
          <div class="row gap-60 align-items-center">
            <div class="col-lg-6">
              <div class="faq-three-left-part mb-20 rel rmb-75">
                <img src={contactImg} alt="Man" />
                <div class="experiences-years">
                  <span class="experiences-years__number">12</span>
                  <span class="experiences-years__text">Years Experiences</span>
                </div>
                <div class="counter-item counter-text-wrap">
                  <div class="counter-item__content">
                    <span class="count-text" data-speed="3000" data-stop="80000">Since</span>
                    <h5 class="counter-title">2011</h5>
                  </div>
                </div>
                <div class="project-complete">
                  <div class="project-complete__icon">
                    <i class="flaticon-charity"></i>
                  </div>
                  <div class="project-complete__content">
                    <h5>Recognized By</h5>
                    <span>Govt of Mahrashtra ,MNC, INC & MSBNPE</span>
                  </div>
                </div>
              </div>
            </div>
            <ContactForm/>
          </div>
        </div>
      </div>
      {/* <!-- Contact page area end --> */}


      {/* <!-- Contact Info area start --> */}
      <div class="contact-info-area pb-85">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-4 col-sm-6">
              <div class="contact-info-item contact-info-item--green">
                <div class="contact-info__icon"><i class="flaticon-phone-call"></i></div>
                <h5>Contact Number</h5>
                <div class="contact-info__text">
                  <Link to="callTo:+(91)9923194784">+ (91) 9923194784</Link><br />
                  <Link to="callTo:+(91)9923003895">+ (91) 9923003895</Link><br />
                  <Link to="callTo:+(91)9588471729">+ (91) 9588471729</Link><br />
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6">
              <div class="contact-info-item contact-info-item--yellow">
                <div class="contact-info__icon"><i class="fas fa-paper-plane"></i></div>
                <h5>Email Address</h5>
                <div class="contact-info__text">
                  <Link to="contact@indiragandhinursing.in">contact@indiragandhinursing.in</Link>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6">
              <div class="contact-info-item">
                <div class="contact-info__icon"><i class="flaticon-pin"></i></div>
                <h5>Address</h5>
                <div class="contact-info__text">
                KHADRABAD PLOT, NEAR JINTUR ROAD HIGHWAY <br />PARBHANI-431401 MAHARASHTRA - INDIA
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Contact Info area end --> */}


      {/* <!-- Location Map Area Start --> */}
      <div class="contact-page-map wow fadeInUp delay-0-2s">
        <div class="our-location">
          <iframe title='map'
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3766.3079986209104!2d76.76167037593937!3d19.26896774588489!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd019d96b6c4dbb%3A0xf8ea5add33bac4c1!2sIndira%20Gandhi%20Nursing%20College!5e0!3m2!1sen!2sin!4v1721120600402!5m2!1sen!2sin"
            style={{ border: 0, width: "100%", allowFullScreen: "", loading: "lazy" }}></iframe>
        </div>
      </div>
      {/* <!-- Location Map Area End --> */}
    </>
  )
}
