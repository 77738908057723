import React from 'react'
import { about1 } from '../utils/Images'
import { Link } from 'react-router-dom'

export const AboutHome = () => {
  return (
    <>
      <div className="about-area py-120">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
            <div className="image">
                      <img src={about1} alt="About" style={{borderRadius: "40px"}} />
                    </div>
              {/* <div className="about-image-part">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="image">
                      <img src={about1} alt="About" />
                    </div>
                    <div className="project-complete mb-30">
                      <div className="project-complete__icon">
                        <i className="flaticon-charity"></i>
                      </div>
                      <div className="project-complete__content">
                        <h5> August 09th, 2011 under</h5>
                        <span>Govt of Mahrashtra ,MNC, INC & MSBNPE</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="image mt-65 rmt-15 rel">
                      <img src={about2} alt="About" />
                      <div className="experiences-years">
                        <span className="experiences-years__number">13</span>
                        <span className="experiences-years__text">Established in 2011</span>
                      </div>
                    </div>
                    <div className="image">
                      <img src={about3} alt="About" />
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="col-lg-6">
              <div className="about-content-part rmt-65">
                <div className="section-title mb-60">
                  <span className="section-title__subtitle mb-10">About us</span>
                  <h2>Indira Gandhi Nursing School is one of the Best <span>leading College</span></h2>
                </div>
                <p>Offering a unique educational friendly environment that prepares the next generation. Indira Gandhi Nursing School Established in 2011 with a view to provide job oriented Professional Nursing Courses (ANM, GNM ).</p>
                <div className="counter-item counter-text-wrap mt-30">
                  <div className="counter-item__icon"><i className="flaticon-solidarity"></i></div>
                  <div className="counter-item__content">
                    <span className="count-text" data-speed="3000" data-stop="876000">ANM</span>
                    <span className="counter-title">Auxiliary Nurse and Midwife</span>
                  </div>
                </div>
                <div className="counter-item counter-text-wrap">
                  <div className="counter-item__icon counter-item__icon--green"><i className="flaticon-help"></i>
                  </div>
                  <div className="counter-item__content">
                    <span className="count-text" data-speed="3000" data-stop="45000">GNM</span>
                    <span className="counter-title">General Nursing and Midwifery</span>
                  </div>
                </div>
                <Link className="btn ml-5 mt-25" to="/about/about-us">Read more</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
