import { Link } from "react-router-dom";
import { PageBanner } from "../components/PageBanner";
import { courses } from "../utils/config";

const Courses = () => {
  return (
    <>
      <PageBanner />
      <div class="our-cause-page py-120 rel z-1">
        <div class="container">
          <div class="row justify-content-center">
            {
              courses.map((item, index) => (
                <div key={index} class="col-xl-4 col-md-6">
                  <div class="cause-two-item">
                    <div class="image">
                      <img src={item.image} alt="Cause" />
                    </div>
                    <div class="content">
                      <div className="mb-50"></div>
                      <h5>{item.name}</h5>
                      <div class="cause-price">
                        <span>Duration : {item.duration}</span>
                        <span>Criteria : {item.criteria}</span>
                      </div>
                      <p>{item.shortDescription}</p>
                      <div class="cause-btn">
                        <Link className="btn" to="/contact-us">Join Us Now</Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </>
  )
};

export default Courses
