import React from 'react'
import { Link } from 'react-router-dom'
import { generalInfo } from '../utils/config'

export const NavbarTop = () => {
  return (
    <>
      <div className="navbar-top pt-15 pb-10 bgc-black navtop--one">
        <div className="container">
          <div className="navtop-inner">
            <ul className="topbar-left">
              <li><span className='text-danger'>Contact Us: </span>{generalInfo.contact}</li>
              <li><i className="flaticon-pin text-danger"></i>{generalInfo.address}</li>
            </ul>
            <ul className="topbar-right">
              <li className="social-area">
                <span>Follow Us - </span>
                {
                  generalInfo.social.map((item, index )=> (
                    <Link key={index} to={item.url} target='_blank'><i className={`fab fa-${item.icon}`}></i></Link>
                  ))
                }
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}
