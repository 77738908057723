import { Link } from "react-router-dom";
import { Error_404 } from "../utils/Images";

const Error = () => {
  return (
    <>
    {/* <PageBanner /> */}
      <div class="error-page-area py-120 text-center">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-xl-6 col-lg-8 col-md-10">
              <img src={Error_404} alt="404 Error"/>
                <div class="section-title pt-55 mb-50">
                  <h2>Opps! This page not fund</h2>
                  <p>Page does not fund or some other error occurred. Go to our Home page</p>
                </div>
                <Link to="/" class="btn">Go to home page</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default Error
